import React, { useState, useEffect } from "react"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import Form from "react-bootstrap/Form"

import { getRefills, postRefills } from '../utils.js'

/**
 * Modal for setting the refill count
 *
 * @param {object} props - deviceData, onClose
 */
const RefillModal = (props) => {
    const { id, deviceData, onClose } = props

    const getRefillData = async (id) => {
        const refills = await getRefills(id)
        console.log(refills)
        return refills.refill_count
    }

    const [refill, setRefill] = useState("")

    useEffect(() => {
        async function getRefillData(id) {
            const refills = await getRefills(id)
            console.log(refills)
            if (refills.refill_count) {
                setRefill(refills.refill_count)
            } else {
                setRefill("Not set")
            }

        }
        try {
            getRefillData(props.id)
        } catch {
            console.log("cannot retrieve refill count")
        }
    },[])

    const handleSubmit = async (evt) => {
        evt.preventDefault()
        await postRefills(props.id, refill)
        alert(`Refill start set to ${refill}`)
        onClose()
    }


    return (
        <Modal show={true} onHide={() => onClose()}>
            <Modal.Header closeButton>Set Refill Count "{deviceData["name"]}"</Modal.Header>
            <Modal.Body>
                <div style={{ padding: "1rem" }}>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formRefillNumber">
                            <Form.Label>Count Starting Point:</Form.Label>
                            <Form.Control
                                placeholder="Enter refill number"
                                value={refill}
                                onChange={(e) => setRefill(e.target.value)}
                            />
                        </Form.Group>

                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default RefillModal
