import React from 'react'

const getAllRecent = async () => {
  const response = await fetch(`https://monitor.herburban.com.au/v1/datasets/`)

  if (response.status === 200) {
    const data = await response.json()
    return data
  } else {
    throw Error('Unable to get data')
  }
}

const getDeviceLogs = async (device_id) => {
  const response = await fetch(`https://monitor.herburban.com.au/v1/datasets/${device_id}`)

  if (response.status === 200) {
    const data = await response.json()
    return data.filter(function (log) {
      return log.hasOwnProperty('Main_Tank')
    })
  } else {
    throw Error('Unable to get data')
  }
}

// TODO: remoteReset and resetCounters should be one function
const remoteReset = async (device_id) => {
  const response = await fetch(`https://monitor.herburban.com.au/v1/actions/Reset/${device_id}`)

  if (response.status === 200) {
    const data = await response.json()
    return data
  } else {
    throw Error('Unable to get data')
  }
}

const getLiveData = async (device_id) => {
  const response = await fetch(`https://monitor.herburban.com.au/v1/actions/get_live/${device_id}`)

  if (response.status === 200) {
    const data = await response.json()
    return data
  } else {
    throw Error('Unable to get data')
  }
}

const resetCounters = async (device_id) => {
  const response = await fetch(`https://monitor.herburban.com.au/v1/actions/Reset_Counters/${device_id}`)

  if (response.status === 200) {
    const data = await response.json()
    return data
  } else {
    throw Error('Unable to get data')
  }
}

const sendRefillEmail = async (device_id) => {
  const response = await fetch(`https://monitor.herburban.com.au/v1/actions/send_refill_email/${device_id}`)

  if (response.status === 200) {
    return await response.json()
  } else {
    throw Error('Unable to send refill email')
  }
}

const sendLowBatteryEmail = async (device_id) => {
  const response = await fetch(`https://monitor.herburban.com.au/v1/actions/send_low_battery_email/${device_id}`)

  if (response.status === 200) {
    return await response.json()
  } else {
    throw Error('Unable to send low battery email')
  }
}

const deleteDeviceCollection = async (device_id) => {
  const response = await fetch(`https://monitor.herburban.com.au/v1/database/${device_id}`)

  if (response.status === 200) {
    return await response.json()
  } else {
    throw Error('Unable to delete device')
  }
}

/**
 * API call for handling user management
 *
 * @param {object} data - data object to be passed
 * @param {string} functionName - name of api function
 */
const manageAccounts = async (data, functionName) => {
  const response = await fetch(`https://monitor.herburban.com.au/v1/account/${functionName}`, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'include', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header)
  })

  if (response.status === 200) {
    const data = await response.json()
    return data
  } else {
    throw Error('Unable to get data')
  }
}

/**
 * API call for handling refill retrieval
 *
 * @param {string} device_id - device id number
 */
const getRefills = async (device_id) => {
  const response = await fetch(`https://monitor.herburban.com.au/v1/refill/${device_id}`, {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'include', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  })

  if (response.status === 200) {
    const data = await response.json()
    return data
  } else {
    throw Error('Unable to get data')
  }
}

/**
 * API call for setting refill count
 *
 * @param {string} device_id - device id number
 * @param {string} refill_count - number to start refills from
 */
const postRefills = async (device_id, refill_count) => {
  const response = await fetch(`https://monitor.herburban.com.au/v1/refill/${device_id}`, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'include', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify({ refill_count: refill_count }), // body data type must match "Content-Type" header)
  })

  if (response.status === 200) {
    const data = await response.json()
    return data
  } else {
    throw Error('Unable to get data')
  }
}

/**
 * Takes battery voltage and returns battery image
 *
 * @param {number} voltage
 */
const displayBatIcon = (voltage) => {
  if (voltage >= 12.05) {
    return <i className="fas fa-battery-full" />
  } else if (voltage >= 11.81) {
    return <i className="fas fa-battery-half" />
  } else if (voltage > 3.75 && voltage < 6) {
    return <i className="fas fa-battery-full" />
  } else if (voltage >= 3 && voltage <= 3.75) {
    return <i className="fas fa-battery-half" />
  } else {
    return <i className="fas fa-battery-empty" />
  }
}

export {
  getAllRecent,
  getDeviceLogs,
  remoteReset,
  resetCounters,
  getLiveData,
  manageAccounts,
  displayBatIcon,
  getRefills,
  postRefills,
  sendRefillEmail,
  deleteDeviceCollection,
  sendLowBatteryEmail,
}
