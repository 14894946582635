import React from 'react'
import autoBind from 'react-autobind'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
// import PropTypes from "prop-types"

import { manageAccounts } from '../../utils.js'

class ManageUsers extends React.Component {
  // static propTypes = {
  // }

  constructor(props) {
    super(props)

    this.state = {
      users: [],
      edit: false,
      addNew: false,
    }
    autoBind(this)
  }

  /**
   * Gets all users
   */
  async getUsers() {
    try {
      const users = await manageAccounts(null, 'get_users')
      this.setState({ users })
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * Runs automatically on page load
   */
  componentDidMount() {
    this.getUsers()
  }

  /**
   * Deletes User Account
   */
  async handleDelete() {
    try {
      const deleteAccount = await manageAccounts(this.state.edit['username'], 'delete_user')
      alert(deleteAccount.response.message)
    } catch (e) {
      console.log(e)
    }
    this.getUsers()
  }

  /**
   * Resets User password
   */
  async handleReset() {
    try {
      const resetPass = await manageAccounts(this.state.edit['username'], 'reset_pass')
      alert(`${this.state.edit['username']} password reset to ${resetPass}`)
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * Assign device to user
   *
   * @param {object} e form val
   */
  async handleSetDevice(e) {
    e.preventDefault()
    try {
      const setUserDevice = await manageAccounts(
        {
          username: this.state.edit['username'],
          device_id: e.currentTarget['0'].value,
        },
        'set_device'
      )
      alert(`${setUserDevice.username} set to ${setUserDevice.device}`)
    } catch (e) {
      console.log(e)
    }
    this.getUsers()
  }

  /**
   * Assign email to user
   *
   * @param {object} e form val
   */
  async handleSetEmail(e) {
    e.preventDefault()
    try {
      const setUserDevice = await manageAccounts(
        {
          username: this.state.edit['username'],
          email: e.currentTarget['0'].value,
        },
        'set_email'
      )
      alert(`${setUserDevice.username} email set to ${setUserDevice.email}`)
    } catch (e) {
      console.log(e)
    }
    this.getUsers()
  }

  /**
   * Create new account
   *
   * @param {object} e - form values object
   */
  async addNewUser(e) {
    e.preventDefault()
    const userMatch = this.state.users.filter((user) => {
      return user.username === e.currentTarget['0'].value
    })

    if (userMatch.length > 0) {
      alert('Username is already in use!')
      return
    }
    try {
      console.log(e.currentTarget)
      const addUser = await manageAccounts(
        {
          username: e.currentTarget['0'].value,
          password: e.currentTarget['1'].value,
          email: e.currentTarget['2'].value,
          device_id: e.currentTarget['3'].value,
          roles: e.currentTarget['4'].checked ? 'Admin' : false,
        },
        'add_user'
      )
      alert(`${addUser.username} added with device: ${addUser.device}`)
      this.setState({ addNew: false })
    } catch (e) {
      console.log(e)
    }
    this.getUsers()
  }

  render() {
    const { users, edit, addNew } = this.state

    return (
      <React.Fragment>
        <div className="user__control-box">
          <div className="table-box">
            <Table striped={true} bordered={true} hover={true}>
              <thead>
                <tr>
                  <th>Username</th>
                  <th>Email</th>
                  <th>Assigned Device</th>
                  <th>Roles</th>
                  <th>
                    <Button variant="secondary" onClick={this.getUsers}>
                      Refresh
                    </Button>
                    {/* <Button
                                            variant="secondary"
                                            onClick={() => this.setState({ addNew: true })}>
                                            Add User
                                        </Button> */}
                  </th>
                </tr>
              </thead>
              <tbody>
                {users &&
                  users.map((user, key) => {
                    return (
                      <tr key={key}>
                        <td>{user['username']}</td>
                        <td>{user['email']}</td>
                        <td>{user['device']}</td>
                        <td>{user['roles'][0]}</td>
                        <td>
                          <Button variant="secondary" onClick={() => this.setState({ edit: user })}>
                            Edit Account
                          </Button>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </Table>
          </div>

          {/* Edit User Modal */}
          <Modal show={!!edit} onHide={() => this.setState({ edit: false })}>
            <Modal.Header closeButton>Edit User "{edit['username']}"</Modal.Header>
            <Modal.Body>
              <div style={{ padding: '1rem' }}>
                <Form onSubmit={this.handleSetDevice}>
                  <Form.Group controlId="formDeviceNumber">
                    <Form.Label>Set Device:</Form.Label>
                    <Form.Control placeholder="Enter device number" />
                  </Form.Group>

                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                </Form>
              </div>
              <div style={{ padding: '1rem' }}>
                <Form onSubmit={this.handleSetEmail}>
                  <Form.Group controlId="formEmail">
                    <Form.Label>Set Email:</Form.Label>
                    <Form.Control placeholder="Enter email address" />
                  </Form.Group>

                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                </Form>
              </div>
              <div style={{ padding: '1rem' }}>
                <Button variant="secondary" sytle={{ height: 'max-content' }} onClick={this.handleReset}>
                  Reset Password
                </Button>
              </div>
              <div style={{ padding: '1rem' }}>
                <Button variant="secondary" sytle={{ height: 'max-content' }} onClick={this.handleDelete}>
                  Delete User Account
                </Button>
              </div>
            </Modal.Body>
          </Modal>

          {/* Add new User Modal */}
          <Modal show={!!addNew} onHide={() => this.setState({ addNew: false })}>
            <Modal.Header closeButton>Add New User</Modal.Header>
            <Modal.Body>
              <div style={{ padding: '1rem' }}>
                <Form onSubmit={this.addNewUser}>
                  <Form.Group controlId="formUsername">
                    <Form.Label>Username:</Form.Label>
                    <Form.Control placeholder="Enter username" />
                  </Form.Group>
                  <Form.Group controlId="formEmail">
                    <Form.Label>Email:</Form.Label>
                    <Form.Control placeholder="Enter email" />
                  </Form.Group>
                  <Form.Group controlId="formPassword">
                    <Form.Label>Password:</Form.Label>
                    <Form.Control placeholder="Enter password" />
                  </Form.Group>
                  <Form.Group controlId="formDeviceNumber">
                    <Form.Label>Device:</Form.Label>
                    <Form.Control placeholder="Enter device number" />
                  </Form.Group>
                  <Form.Group controlId="formAdmin">
                    <Form.Check type="checkbox" id="isAdmin" label="Administrator" />
                  </Form.Group>

                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                </Form>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </React.Fragment>
    )
  }
}

export default ManageUsers
