import React from 'react'
import autoBind from 'react-autobind'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import PropTypes from 'prop-types'

import { getDeviceLogs, displayBatIcon } from '../utils.js'

class DisplayOne extends React.Component {
  static propTypes = {
    deviceID: PropTypes.string, // Device ID number
    handleClose: PropTypes.func, // closes log
  }

  constructor(props) {
    super(props)

    this.state = {
      deviceLogs: null,
    }

    autoBind(this)
  }

  /**
   * Gets all logs from device
   */
  async displayDeviceData() {
    try {
      const deviceLogs = await getDeviceLogs(this.props.deviceID)
      this.setState({ deviceLogs })
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * Runs automatically on page load
   */
  componentDidMount() {
    this.displayDeviceData()
  }

  render() {
    const { deviceLogs } = this.state
    const { deviceID, handleClose } = this.props

    if (!deviceLogs || !deviceLogs[0]) {
      return (
        <div>
          <h2>No logs found</h2>
          <Button variant="secondary" onClick={handleClose}>
            Close Log
          </Button>
        </div>
      )
    }

    return (
      <React.Fragment>
        {deviceLogs && (
          <h2>
            {deviceLogs[0]['name']}: {deviceID}
          </h2>
        )}
        <Button variant="secondary" onClick={handleClose}>
          Close Log
        </Button>
        <div className="table-box">
          <Table striped={true} bordered={true} hover={true}>
            <thead>
              <tr>
                <th>Date-time</th>
                <th>Fault Code</th>
                <th>Main Tank</th>
                <th>Battery Volt</th>
                <th>Tank Refill Count</th>
                <th>Last Tank Refill</th>
                <th>Tier 1 Refill Count</th>
                <th>Tier 2 Refill Count</th>
                <th>Tier 3 Refill Count</th>
                <th>Tier 4 Refill Count</th>
                <th>Reset Count</th>
                <th>Humidity</th>
                <th>Water Temp</th>
                <th>Enc. Temp</th>
                <th>Battery Alarm</th>
                <th>Input Power</th>
                <th>Version</th>
              </tr>
            </thead>
            <tbody>
              {deviceLogs &&
                deviceLogs.map((entry, key) => {
                  return (
                    <tr key={key}>
                      <td>{entry['time']}</td>
                      <td>{entry['Fault_Code']}</td>
                      <td>{entry['Main_Tank']}</td>
                      <td>{entry['Batt_Volt']}</td>
                      <td>{entry['tank_refill_count_mongo'] || entry['Tank_Refill_Count'] || '0'}</td>
                      <td>{entry['last_refill_date']}</td>
                      <td>
                        {entry['tier_1_refill_count_mongo'] || entry['Tier_1_Refill_Count'] || '0'} <br />
                        {entry['tier_1_last_refill_date']}
                      </td>
                      <td>
                        {entry['tier_2_refill_count_mongo'] || entry['Tier_2_Refill_Count'] || '0'} <br />
                        {entry['tier_2_last_refill_date']}
                      </td>
                      <td>
                        {entry['tier_3_refill_count_mongo'] || entry['Tier_3_Refill_Count'] || '0'} <br />
                        {entry['tier_3_last_refill_date']}
                      </td>
                      <td>{entry['tier_4_refill_count_mongo'] || entry['Tier_4_Refill_Count'] || '0'}</td>
                      <td>{entry['reset_count_mongo'] || entry['Reset_Count']}</td>
                      <td>{entry['Humidity']}</td>
                      <td>{entry['Water_Temp']}</td>
                      <td>{entry['Enclo_Temp']}</td>
                      <td>{entry['battery_ala']}</td>
                      <td>{entry['Input_Power']}</td>
                      <td>{entry['Version']}</td>
                    </tr>
                  )
                })}
            </tbody>
          </Table>
        </div>
      </React.Fragment>
    )
  }
}

export default DisplayOne
