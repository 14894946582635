import React from 'react'
import { Nav, Navbar, Button } from 'react-bootstrap'
import { Route, Switch } from 'react-router-dom'
import autoBind from 'react-autobind'
import logo from './images/Herb+Urban+Logo-02+no+border.png'
import DisplayAll from './components/DisplayAll.jsx'
import DisplayOne from './components/DisplayOne.jsx'
import ManageUsers from './components/ManageUsers/ManageUsers.jsx'
import Dashboard from './components/Dashboard/Dashboard.jsx'
import { manageAccounts } from './utils'

import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'

class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      view: false,
      viewLogId: '',
      manageUsers: false,
      viewDashboard: false,
      currentUser: {},
    }
    autoBind(this)
  }

  componentDidMount() {
    if (window.account) {
      manageAccounts(window.account, 'get_user').then((user) => {
        this.setState({ currentUser: user })
      })
    }
  }

  /**
   * Opens an individual device log
   *
   * @param {string} deviceID - Device ID number
   */
  handleOpenLogs(deviceID) {
    this.setState({ view: 'logs', viewLogId: deviceID })
  }

  /**
   * Closes individual log
   */
  handleCloseLogs() {
    this.setState({ view: false, viewLogId: '' })
  }

  /**
   * changes view to device dashboard
   */
  handleOpenDashboard(deviceID) {
    this.setState({ view: 'dashboard', viewDashboard: deviceID })
  }

  render() {
    const { view, viewLogId, viewDashboard, manageUsers, currentUser } = this.state

    return (
      <div className="App">
        <Navbar variant="dark" expand="lg">
          <Navbar.Brand href="https://herburban.com.au/">
            <img alt="" src={logo} className="d-inline-block align-top logo fade-in" />
          </Navbar.Brand>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link href="https://herburban.com.au/shop">Shop</Nav.Link>
              <Nav.Link href="https://herburban.com.au/custom">Custom</Nav.Link>
              <Nav.Link href="https://herburban.com.au/about">About</Nav.Link>
              <Nav.Link href="https://herburban.com.au/contact">Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Switch>
          <Route path="/admin">
            <Button variant="secondary" disabled={manageUsers} onClick={() => this.setState({ manageUsers: true })}>
              Manage Users
            </Button>
            <Button
              variant="secondary"
              disabled={!manageUsers && !view}
              onClick={() => this.setState({ manageUsers: false, view: false })}
            >
              Admin Home
            </Button>
            {manageUsers && <ManageUsers />}
            {!manageUsers && (
              <div>
                {view === 'logs' && <DisplayOne deviceID={viewLogId} handleClose={this.handleCloseLogs} />}

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {!view && <DisplayAll openLogs={this.handleOpenLogs} openDashboard={this.handleOpenDashboard} />}
                  {view === 'dashboard' && <Dashboard deviceID={viewDashboard} />}
                </div>
              </div>
            )}
          </Route>
          <Route path="/view">
            {currentUser && <Dashboard username={currentUser.username} deviceID={currentUser.device} />}
          </Route>
          <Route path="/"></Route>
        </Switch>
      </div>
    )
  }
}

export default App
