import React from 'react'
import autoBind from 'react-autobind'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import PropTypes from 'prop-types'

import RefillModal from './RefillModal.jsx'
import {
  getAllRecent,
  remoteReset,
  resetCounters,
  getLiveData,
  sendRefillEmail,
  sendLowBatteryEmail,
  deleteDeviceCollection,
} from '../utils.js'

import './DisplayAll.scss'
import { Spinner } from 'react-bootstrap'

class DisplayAll extends React.Component {
  static propTypes = {
    openLogs: PropTypes.func, // function to open individual log
    openDashboard: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.state = {
      deviceData: null,
      dataPending: [],
      setRefillModal: false,
      activeButton: null,
    }

    autoBind(this)
  }

  /**
   * Converts string date into timestamp
   *
   * @param {string} strDate
   */
  getBackground(strDate) {
    if (!strDate) return 'white'
    const logDate = new Date(strDate.split('/')[2].substring(0, 4), strDate.split('/')[1] - 1, strDate.split('/')[0])

    let now = Date.now()

    const oneDay = 24 * 60 * 60 * 1000
    const sevenDays = 24 * 60 * 60 * 1000 * 7

    if (logDate < now - sevenDays) {
      return 'lightcoral'
    } else if (logDate < now - oneDay) {
      return 'yellow'
    } else {
      return ''
    }
  }

  /**
   * Gets the most recent log entry from all devices
   */
  async displayEachDevice() {
    try {
      const deviceData = await getAllRecent()
      this.setState({ deviceData })
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * Checks if request is already pending
   *
   * @param {string} id - device ID
   */
  checkPending(id) {
    return this.state.dataPending.includes(id)
  }

  /**
   * API to update device data immediately
   *
   * @param {string} id - device ID
   */
  async handleGetLiveData(id) {
    try {
      const liveData = await getLiveData(id)
      console.log(liveData)

      var index = this.state.dataPending.indexOf(id)
      let pending = this.state.dataPending.splice(index, 1)

      this.setState({ dataPending: pending })
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * Gets data and disables button
   *
   * @param {string} id - device ID
   */
  handleGetData(id) {
    let pending = this.state.dataPending
    pending.push(id)
    this.setState({ dataPending: pending })
    this.handleGetLiveData(id)
  }

  /**
   * Runs when the page is loaded
   */
  componentDidMount() {
    this.displayEachDevice()
  }

  /**
   * Opens setRefillModal
   * @param {string} id - device id
   */
  handleOpenSetRefill(id) {
    this.setState({ setRefillModal: id })
  }

  /**
   * Closes setRefillModal
   */
  handleCloseSetRefill() {
    this.setState({ setRefillModal: false })
  }

  sortFaultCode = () => {
    const { deviceData } = this.state
    const sortedData = Object.keys(deviceData)
      .sort((a, b) => {
        let faultCodeA = Number(deviceData[a]['Fault_Code'])
        let faultCodeB = Number(deviceData[b]['Fault_Code'])

        if (faultCodeA === 10) return -1
        if (faultCodeB === 10) return 1

        return faultCodeB - faultCodeA
      })
      .reduce((obj, key) => ({ ...obj, [key]: deviceData[key] }), {})

    this.setState({ deviceData: sortedData })
    this.setState({ activeButton: 'lastReading' })
    this.setState({ activeButton: 'faultCode' })
  }

  sortMainTank = () => {
    const { deviceData } = this.state
    const sortedData = Object.keys(deviceData)
      .sort((a, b) => {
        return deviceData[b]['Main_Tank'] - deviceData[a]['Main_Tank']
      })
      .reduce((obj, key) => ({ ...obj, [key]: deviceData[key] }), {})

    this.setState({ deviceData: sortedData })
    this.setState({ activeButton: 'mainTank' })
  }

  parseDateString = (dateString) => {
    const [day, month, yearAndTime] = dateString.split('/')
    const [year, time] = yearAndTime.split(' ')
    return new Date([month, day, year].join('/') + ' ' + time)
  }

  sortLastReading = () => {
    const { deviceData } = this.state
    const sortedData = Object.keys(deviceData)
      .sort((a, b) => {
        const timeA = deviceData[a]['time']
        const timeB = deviceData[b]['time']

        // Both records have time values - sort normally
        if (timeA && timeB) {
          const dateA = this.parseDateString(timeA)
          const dateB = this.parseDateString(timeB)
          return dateA.getTime() - dateB.getTime()
        }
        // Only A has time - A goes first
        else if (timeA) {
          return -1
        }
        // Only B has time - B goes first
        else if (timeB) {
          return 1
        }
        // Neither have time - doesn't matter
        else {
          return 0
        }
      })
      .reduce((obj, key) => ({ ...obj, [key]: deviceData[key] }), {})

    this.setState({ deviceData: sortedData })
    this.setState({ activeButton: 'lastReading' })
  }

  render() {
    const { deviceData, setRefillModal } = this.state
    const { openLogs, openDashboard } = this.props

    const handleSendRefillEmail = async (id) => {
      let areYouSure = confirm("Send a 'Tank Empty' email to this device owner?")
      if (areYouSure) {
        await sendRefillEmail(id)
      }
    }

    const handleSendLowBatteryEmail = async (id) => {
      let areYouSure = confirm("Send a 'Battery Low' email to this device owner?")
      if (areYouSure) {
        await sendLowBatteryEmail(id)
      }
    }

    const handleDeleteDeviceCollection = async (id) => {
      let areYouSure = confirm('This will delete this device and all records it contains forever!')
      if (areYouSure) {
        await deleteDeviceCollection(id)
        this.setState({ deviceData: false })
        await this.displayEachDevice()
      }
    }

    return (
      <div className="table_parent-box">
        {!deviceData && <Spinner animation={'border'} />}
        <div className="w-30 flex flex-row justify-center">
          Sort by:{' '}
          <Button
            variant={this.state.activeButton === 'faultCode' ? 'primary' : 'secondary'}
            onClick={this.sortFaultCode}
          >
            Fault Code
          </Button>
          <Button
            variant={this.state.activeButton === 'mainTank' ? 'primary' : 'secondary'}
            onClick={this.sortMainTank}
          >
            Main Tank
          </Button>
          <Button
            variant={this.state.activeButton === 'lastReading' ? 'primary' : 'secondary'}
            onClick={this.sortLastReading}
          >
            Last Reading
          </Button>
        </div>
        {deviceData &&
          Object.keys(deviceData).map((id, key) => {
            return (
              <div className="device_summary-box" key={key}>
                {/* <div className="table-box"> */}
                <h3>Device Name: {deviceData[id]['name']}</h3>
                ID: {id}
                <div className="device_summary_button-box">
                  <Button variant="secondary" onClick={() => openLogs(id)}>
                    View Logs
                  </Button>
                  <Button variant="secondary" onClick={() => openDashboard(id)}>
                    View Dashboard
                  </Button>
                  <Button variant="secondary" onClick={() => remoteReset(id)}>
                    Reset
                  </Button>
                  <Button variant="secondary" onClick={() => resetCounters(id)}>
                    Reset Count
                  </Button>
                  <Button variant="secondary" onClick={() => this.handleGetData(id)} disabled={this.checkPending(id)}>
                    Get Live Data
                  </Button>
                  <Button variant="secondary" onClick={() => this.handleOpenSetRefill(id)}>
                    Set Refill Alert
                  </Button>
                  <Button variant="secondary" onClick={() => handleSendRefillEmail(id)}>
                    Send Refill Email
                  </Button>
                  <Button variant="secondary" onClick={() => handleSendLowBatteryEmail(id)}>
                    Send Low Battery Email
                  </Button>
                  <Button variant="danger" onClick={() => handleDeleteDeviceCollection(id)}>
                    Delete Device
                  </Button>
                </div>
                <Table striped={true} bordered={true} hover={true} className="display-all-table">
                  <thead>
                    <tr>
                      <th>Fault Code</th>
                      <th>Main Tank</th>
                      <th>Battery Volt</th>
                      <th>Tank Refill Count</th>
                      <th>Last Tank Refill</th>
                      <th>Tier 1 Refill Count</th>
                      <th>Tier 2 Refill Count</th>
                      <th>Tier 3 Refill Count</th>
                      <th>Tier 4 Refill Count</th>
                      <th>Reset Count</th>
                      <th>Humidity</th>
                      <th>Water Temp</th>
                      <th>Encl. Temp</th>
                      <th>Battery Alarm</th>
                      <th>Version</th>
                      <th>Last Reading</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{deviceData[id]['Fault_Code']}</td>
                      <td>{deviceData[id]['Main_Tank']}</td>
                      <td>{deviceData[id]['Batt_Volt']}</td>
                      <td>{deviceData[id]['tank_refill_count_mongo'] || deviceData[id]['Tank_Refill_Count'] || '0'}</td>
                      <td>{deviceData[id]['last_refill_date']}</td>
                      <td>
                        {deviceData[id]['tier_1_refill_count_mongo'] || deviceData[id]['Tier_1_Refill_Count'] || '0'}
                      </td>
                      <td>
                        {deviceData[id]['tier_2_refill_count_mongo'] || deviceData[id]['Tier_2_Refill_Count'] || '0'}
                      </td>
                      <td>
                        {deviceData[id]['tier_3_refill_count_mongo'] || deviceData[id]['Tier_3_Refill_Count'] || '0'}
                      </td>
                      <td>
                        {deviceData[id]['tier_4_refill_count_mongo'] || deviceData[id]['Tier_4_Refill_Count'] || '0'}
                      </td>
                      <td>{deviceData[id]['reset_count_mongo'] || deviceData[id]['Reset_Count']}</td>
                      <td>{deviceData[id]['Humidity']}</td>
                      <td>{deviceData[id]['Water_Temp']}</td>
                      <td>{deviceData[id]['Enclo_Temp']}</td>
                      <td>{deviceData[id]['battery_ala']}</td>
                      <td>{deviceData[id]['Version']}</td>
                      <td
                        style={{
                          backgroundColor: this.getBackground(deviceData[id]['time']),
                        }}
                      >
                        {deviceData[id]['time']}
                      </td>
                    </tr>
                  </tbody>
                </Table>
                {/* </div> */}
              </div>
            )
          })}

        {setRefillModal && deviceData && (
          <RefillModal
            id={setRefillModal}
            onClose={this.handleCloseSetRefill}
            deviceData={deviceData[setRefillModal]}
          />
        )}
      </div>
    )
  }
}

export default DisplayAll
