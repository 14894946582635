import React from 'react'
import autoBind from 'react-autobind'
import PropTypes from 'prop-types'
import plantFood from '../../images/plant-food.jpg'
import neemOil from '../../images/Neem-oil.jpg'
import coconutHusk from '../../images/coconut-husk.jpg'
import seedlings from '../../images/Seedlings.jpg'

import { displayBatIcon, getDeviceLogs, manageAccounts } from '../../utils.js'

import './Dashboard.scss'
import { max } from 'lodash'
import LiquidFill from './LiquidFill.jsx'

class Dashboard extends React.Component {
  static propTypes = {
    deviceID: PropTypes.string, // Device ID number
  }

  constructor(props) {
    super(props)

    this.state = {
      deviceLogs: null,
      userDevice: null,
    }

    autoBind(this)
  }

  /**
   * Converts string date into timestamp
   *
   * @param {string} strDate
   */
  timeToStamp(strDate) {
    if (!strDate) return 0
    const timeStamp = new Date(strDate.split('/')[2].substring(0, 4), strDate.split('/')[1] - 1, strDate.split('/')[0])

    return timeStamp
  }

  /**
   * Gets all logs from device
   */
  async displayDeviceData(deviceID) {
    try {
      let deviceLogs = await getDeviceLogs(deviceID)

      // calc latest tier refill date
      if (deviceLogs[0].hasOwnProperty('tier_1_last_refill_date')) {
        let tier_1 = this.timeToStamp(deviceLogs[0].tier_1_last_refill_date)
        let tier_2 = this.timeToStamp(deviceLogs[0].tier_2_last_refill_date)
        let tier_3 = this.timeToStamp(deviceLogs[0].tier_3_last_refill_date)
        if (max(tier_1, tier_2, tier_3) === tier_1) {
          deviceLogs[0]['latest_tier_date'] = deviceLogs[0].tier_1_last_refill_date
        } else if (max(tier_1, tier_2, tier_3) === tier_2) {
          deviceLogs[0]['latest_tier_date'] = deviceLogs[0].tier_2_last_refill_date
        } else {
          deviceLogs[0]['latest_tier_date'] = deviceLogs[0].tier_3_last_refill_date
        }
      }

      this.setState({ deviceLogs })
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * Calculates time difference and returns a string
   *
   * @param {string} fill
   * @param {string} empty
   */
  getTimeTillEmpty(fill, empty) {
    const ft = fill.match(/(\d+)\/(\d+)\/(\d+) (\d+):(\d+)/)
    const et = empty.match(/(\d+)\/(\d+)\/(\d+) (\d+):(\d+)/)
    // console.log(parseInt(ft[3]), ft[2] - 1, parseInt(ft[1]), ft[4] - 1, parseInt(ft[5]));
    const fillTime = Date.UTC(parseInt(ft[3]), ft[2] - 1, parseInt(ft[1]), ft[4] - 1, parseInt(ft[5]))
    const emptyTime = Date.UTC(parseInt(et[3]), et[2] - 1, parseInt(et[1]), et[4] - 1, parseInt(et[5]))
    if (emptyTime <= fillTime) return false
    const timeDiff = fillTime - emptyTime
    const date = new Date(timeDiff)
    let str = ''
    str += date.getUTCDate() - 1 + ' days, '
    str += date.getUTCHours() + ' hours, '
    str += date.getUTCMinutes() + ' minutes, '
    return str
  }

  dhm(t) {
    let cd = 24 * 60 * 60 * 1000,
      ch = 60 * 60 * 1000,
      d = Math.floor(t / cd),
      h = Math.floor((t - d * cd) / ch),
      m = Math.round((t - d * cd - h * ch) / 60000)
    // pad = function (n) {
    //     return n < 10 ? '0' + n : n;
    // };
    if (m === 60) {
      h++
      m = 0
    }
    if (h === 24) {
      d++
      h = 0
    }
    const days = `${d} Days,`
    const hours = `${h} Hours,`
    const minutes = `${m} Minutes`

    return `${d ? days : ''} ${h ? hours : ''} ${m ? minutes : ''}`
  }

  /**
   * gets and assigns device id from user account name
   */
  async getUserDevice() {
    try {
      const userDevice = await manageAccounts(this.props.username, 'get_device_number')
      this.setState({ userDevice })
      this.displayDeviceData(userDevice)
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * Runs automatically on page load
   */
  componentDidUpdate(prevProps) {
    const { deviceID } = this.props
    // This will run whenever deviceID changes
    if (prevProps.deviceID !== deviceID) {
      if (deviceID) {
        this.setState({ userDevice: deviceID })
        this.displayDeviceData(deviceID)
      }
    }
  }

  componentDidMount() {
    const { deviceID } = this.props
    if (deviceID) {
      this.setState({ userDevice: deviceID })
      this.displayDeviceData(deviceID)
    }
  }

  render() {
    const { deviceLogs, userDevice } = this.state
    const { deviceID } = this.props

    if (!userDevice) {
      return <div className="message">Please contact info@herburban.com.au for access.</div>
    }

    if (!deviceLogs) return <p>Loading...</p>

    return (
      <div className="dashboard-column">
        <div className="dashboard">
          {/* <div className="lh-column">
                        <div className="chart-container">
                            Temperature History
                            <TempChart dataset={deviceLogs}/>
                        </div>
                    </div> */}
          <div className="title_readings-box">{deviceLogs[0].name ? deviceLogs[0].name + ' ' : ''}Readings</div>
          <div className="rh-column">
            {deviceLogs[0].Enclo_Temp && (
              <div className="currentTemp">
                Temperature
                <h1>{deviceLogs[0].Enclo_Temp}</h1>
              </div>
            )}
            {deviceLogs[0].Humidity > 0 && (
              <div className="currentHum">
                Humidity
                <h1>{deviceLogs[0].Humidity}</h1>
              </div>
            )}
            {deviceLogs[0].Water_Temp ? (
              <div className="waterTemp">
                Water Temperature
                <h1>{deviceLogs[0].Water_Temp}</h1>
              </div>
            ) : (
              ''
            )}

            {deviceLogs[0].Batt_Volt ? (
              <div className="battVolt">
                Battery
                <div className="batt_icon">{displayBatIcon(deviceLogs[0].Batt_Volt)}</div>
              </div>
            ) : (
              ''
            )}

            {deviceLogs[0].hasOwnProperty('Main_Tank') ? (
              <div className="battVolt">
                Main Tank
                <h1>{deviceLogs[0].Main_Tank ? 'Empty' : 'OK'}</h1>
              </div>
            ) : (
              ''
            )}

            {deviceLogs[0].hasOwnProperty('last_refill_date') ? (
              <div className="lastRefill">
                Last Tank Refill
                <h4>{deviceLogs[0].last_refill_date}</h4>
              </div>
            ) : (
              ''
            )}

            {deviceLogs[0].hasOwnProperty('Tier_1_Refill_Count') ? (
              <div className="tierRefill">
                Tier 1 Refills
                <h1>{deviceLogs[0].Tier_1_Refill_Count}</h1>
              </div>
            ) : (
              ''
            )}

            {deviceLogs[0].hasOwnProperty('Tier_2_Refill_Count') ? (
              <div className="tierRefill">
                Tier 2 Refills
                <h1>{deviceLogs[0].Tier_2_Refill_Count}</h1>
              </div>
            ) : (
              ''
            )}

            {deviceLogs[0].hasOwnProperty('Tier_3_Refill_Count') ? (
              <div className="tierRefill">
                Tier 3 Refills
                <h1>{deviceLogs[0].Tier_3_Refill_Count}</h1>
              </div>
            ) : (
              ''
            )}

            {deviceLogs[0].hasOwnProperty('latest_tier_date') ? (
              <div className="lastRefill">
                Last Tier Refill
                <h4>{deviceLogs[0].latest_tier_date}</h4>
              </div>
            ) : (
              ''
            )}

            {deviceLogs[0].hasOwnProperty('time_to_empty') ? (
              <div className="lastRefill">
                Tank Emptied In
                <h4>{this.dhm(deviceLogs[0].time_to_empty * 1000)}</h4>
              </div>
            ) : (
              <>
                {deviceLogs[0].hasOwnProperty('last_refill_date') &&
                deviceLogs[0].hasOwnProperty('empty_date') &&
                this.getTimeTillEmpty(deviceLogs[0].last_refill_date, deviceLogs[0].empty_date) ? (
                  <div className="lastRefill">
                    Tank Emptied In
                    <h4>{this.getTimeTillEmpty(deviceLogs[0].last_refill_date, deviceLogs[0].empty_date)}</h4>
                  </div>
                ) : (
                  ''
                )}
              </>
            )}

            {(deviceID === 'e00fce68a53957b6616fd1e8' || deviceID === 'e00fce683a40fade0ff6731d') && (
              <>
                {!!deviceLogs[0].Ambient_Temp && deviceID !== 'e00fce683a40fade0ff6731d' && (
                  <div className="ambientTemp">
                    Ambient Temperature
                    <h1>{deviceLogs[0].Ambient_Temp.toFixed(2)}</h1>
                  </div>
                )}
                {!!deviceLogs[0].Air_Pressure && deviceID !== 'e00fce683a40fade0ff6731d' && (
                  <div className="airPressure">
                    Air Pressure
                    <h1>{deviceLogs[0].Air_Pressure.toFixed(2)}</h1>
                  </div>
                )}
                {!!deviceLogs[0].Soil_Temp && (
                  <div className="soilTemp">
                    Soil Temperature
                    <h1>{deviceLogs[0].Soil_Temp.toFixed(2)}</h1>
                  </div>
                )}
                {!!deviceLogs[0].Soil_Moisture && (
                  <div className="soilMoisture">
                    Soil Moisture
                    {/* <h1>{deviceLogs[0].Soil_Moisture.toFixed(2)}</h1> */}
                    <LiquidFill moistureReading={deviceLogs[0].Soil_Moisture} />
                  </div>
                )}
              </>
            )}
          </div>
          <div className="title_refills-box">Refills</div>
          <div className="rh-column">
            <a className="shop-link" href="https://herburban.com.au/shop/plant-food">
              <img className="shop-image" src={plantFood} />
              <div className="shop_item-title">Plant Food</div>
              {/*<div className="shop_item-price">$25.00 AUD</div>*/}
            </a>
            <a className="shop-link" href="https://herburban.com.au/shop/neem-oil">
              <img className="shop-image" src={neemOil} />
              <div className="shop_item-title">Neem Oil</div>
              {/*<div className="shop_item-price">$15.50 AUD</div>*/}
            </a>
            <a className="shop-link" href="https://herburban.com.au/shop/coconut-husk">
              <img className="shop-image" src={coconutHusk} />
              <div className="shop_item-title">Coconut Husk</div>
              {/*<div className="shop_item-price">$35.00 AUD</div>*/}
            </a>
            <a className="shop-link" href="https://herburban.com.au/shop/seedlings">
              <img className="shop-image" src={seedlings} />
              <div className="shop_item-title">Seedlings and Established Plants</div>
              {/*<div className="shop_item-price">from $8.00 AUD</div>*/}
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default Dashboard
